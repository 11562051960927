<template>
  <div id="app">
    <div
      v-if="generalMsg.text != ''"
      class="top-banner"
      :style="
        `background-color:` +
        generalMsg.background_color +
        `; color:` +
        generalMsg.text_color +
        `;`
      "
    >
      <span class="texttop" v-html="generalMsg.text"></span>
    </div>
    <router-view />
  </div>
</template>
<script>
import { isServer } from "@storefront/core/helpers";
import config from "@config";
const contentList = [
  "side-menu-cms",
  "footer_menu_information",
  "footer_menu_service",
  "footer_menu_quick_links",
  "footer_contact",
  "order_today_tomorrow_at_home",
  "free_shipping",
  "our_advantages",
  "home_pictures",
  "header_seperator_title",
  "contact_page_block",
  "merken",
  "footer_copyright",
  "bundle_tips_alternative",
  "homepage-text",
  "checkout_succes",
];
const sliders = [
  "home",
  "home_gallery",
  "payment_providers",
  "delivery_partners",
  "discount_header",
  "discount_header_mobile",
];

export default {
  name: "Home",
  metaInfo() {
    return {
      htmlAttrs: { lang: this.$i18n.locale },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      titleTemplate: "%s | " + config.app_name,
    };
  },
  computed: {
    cmsDefaultPageContent() {
      return this.$store.state.cmsPage.default;
    },
    generalMsg() {
      return this.$store.state.messages.generalMsg;
    },
  },
  watch: {
    "$store.state.messages.msgItems": async function () {
      const msgs = await this.$store.dispatch("messages/giveLastMessages");
      msgs.forEach(function (msg) {
        this.makeToast(msg);
      }, this);
    },
  },
  components: {},
  async serverPrefetch() {
    this.$store.dispatch("forms/loadForms");
    this.$store.dispatch("faq/load");
    await this.$store.dispatch("messages/loadGeneralMsg");
    await this.$store.dispatch("product/loadBrandSlider");
    await this.$store.dispatch("menu/loadMenu");
    await this.$store.dispatch("user/loadCountries");
    await this.$store.dispatch("product/productReviewRatingsMetadata");
    // await this.$store.dispatch("stores/load");
    await this.$store.dispatch("home/loadBundles");
    // await this.$store.dispatch("blog/load");
    await this.$store.dispatch("cmsPage/loadDefault", {
      id: "home",
    });
    await this.$store.dispatch("sliders/multiple", {
      key: "identifier",
      value: sliders,
    });

    await this.$store.dispatch("cmsBlock/multiple", {
      key: "identifier",
      value: contentList,
    });
    await this.$store.dispatch("cart/loadPickupLocations");
  },
  created() {
    if (!isServer) {
      if (this.$cookies.get("websiteCookie") == "true") {
        this.$gtm.enable(true);
        this.$gtm.debug(true);
      }
    }
  },
  async mounted() {
    this.$store.dispatch("cart/loadCart");
    this.$store.dispatch("user/loadUser");
  },
  methods: {
    makeToast(msg) {
      this.$bvToast.toast(msg.text, {
        variant: msg.type,
        autoHideDelay: 5000,
        toaster: "b-toaster-top-full",
        solid: true,
        append: true,
      });
    },
  },
};
</script>

<style lang="scss">
.toast-body {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
#app {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  .modal-open & {
    width: 100vw;
  }
}

.texttop {
  display: inline-block;
  position: relative;
  margin-top: 5px;
  margin-bottom: -15px;
}

.top-banner {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.22rem;
  position: relative;
}

a {
  transition: 0.3s color ease-in-out;
}

.resize-active * {
  transition: none !important;
}
.best-seller--category {
  font-size: 24px;
  letter-spacing: -0.025em;
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  background: #000;
  color: white;
  padding: 6px 10px;
  min-width: 213px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.section-title {
  font-size: 18px;
  font-weight: normal;
  display: block;
  text-align: center;
  margin-bottom: 19px;
}
</style>
